import Icon from "@identitybuilding/idb-react-iconlib";
import { Button, OsnCheckbox, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import axios from "axios";
import { useState, useEffect } from "react";
import store from "../store";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import MapMarker from "@identitybuilding/idb-react-map/dist/MapMarker";
import { latLngBounds } from 'leaflet'
import { useHistory, useParams } from "react-router-dom";

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

const Address = (props) => {
  const [data, setData] = useState(props.data);
  const [loaded, setLoaded] = useState(true);
  const [bounds, setBounds] = useState("");
  const dispatch = useDispatch();
  const [address, setAddress] = useState([]); // integer state
  const lang = useSelector((state) => state.general.lang);
  const forceUpdate = useForceUpdate();
  let { est, ent } = useParams();
  const translate = useSelector((state) => state.general.translate);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    changeMarker()
  }, [])
  
const changeMarker = () => {
  let marker = [{
    "data": {
        "vat": data.enterprise_number,
        "id": est,
        "zoomed": false,
        "address": {
            "lat": data.address.latitude,
            "long": data.address.longitude,
            "line_1": "Hogeweg 51",
            "line_2": "9320 Erembodegem (Aalst)"
        },
        "logos": []
    },
    "index": 0
}]
  if(data.establishment_logo.length > 0){ 
    marker[0].data.logos.push({
      "logo": data.establishment_logo[0].logo,
      "default": true,
      "color": ""
    })
  }

  const bounds = latLngBounds() // seemed to work without having to pass init arg
  marker.forEach(coords => {
      // this.state.addresses.forEach(coords => {
      bounds.extend([coords.data.address.lat, coords.data.address.long])
  })
  if (marker[0].data.address.lat !== null && marker[0].data.address.long !== null){
    setBounds(bounds)
  }
    
  setAddress(marker)
}

  const patchData = (data, tab) => {
    let copy = data
        if(tab > copy.available_tabs){copy.available_tabs = tab}
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "address"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const changeAddress = (e, target) => {
    let copy = data;
    if (target === "show_address"){copy.show_address = !copy.show_address;}
    else{copy.address[target] = e.target.value;}
    

    setData(copy);
    setLoaded(false);
    changeMarker()
    forceUpdate()
  };
  const changeLngLat = (e) => {
    let copy = data

    copy.address.longitude = e.latlng.lng
    copy.address.latitude = e.latlng.lat

    setLoaded(false);
    changeMarker()
    forceUpdate()
    setData(copy);

  }

  return ( 
    <section className="c-address">
      <div className="intro">
        <h2>
          <span className="typo">04</span>
        </h2>
        <div>
          <h2>{translate('where_can_visitors_reach_you')}</h2>
          <p>
            {translate('important_right_location')} <br /> {translate('through_the_map_position_marker')}
          </p>
        </div>
      </div>
      <div className="section-container">
        <form className="section">
        {data.juridical_type === "NATURAL_PERSON" &&
          <OsnCheckbox
              checked={!data.show_address}
              value="Adres verbergen"
              onChange={(e) => {changeAddress(e, "show_address")}
              }
            />
          }
          <span className="L">
            <OsnInputText required title={translate('ci_streetname')} value={data.address.streetname} icon="Home" onChange={(e) => changeAddress(e, "streetname")} />
          </span>
          <span className="XS">
            <OsnInputText required title={translate('ci_house_number')} value={data.address.house_number} onChange={(e) => changeAddress(e, "house_number")} />
          </span>
          <span className="XS">
            <OsnInputText required title={translate('ci_box')} value={data.address.box_number} onChange={(e) => changeAddress(e, "box_number")} />
          </span>
          <span className="XS">
            <OsnInputText required title={translate('ci_postal_code')} value={data.address.postal_code} onChange={(e) => changeAddress(e, "postal_code")} />
          </span>
          <span className="M">
            <OsnInputText required title={translate('ci_sub_municipality')} value={data.address.sub_municipality} icon="Mapmarker" onChange={(e) => changeAddress(e, "sub_municipality")} />
          </span>
          <span className="S">
            <OsnInputText required title={translate('ci_municipality')} value={data.address.municipality} icon="Mapmarker" onChange={(e) => changeAddress(e, "municipality")} />
          </span>
          <span className="MM">
            <OsnInputText required title="Longitude" value={data.address.longitude} icon="Mapmarker" onChange={(e) => changeAddress(e, "longitude")} />
          </span>
          <span className="MM">
            <OsnInputText required title="Latitude" value={data.address.latitude} icon="Mapmarker" onChange={(e) => changeAddress(e, "latitude")} />
          </span>
          <span className="XL">
            <OsnInputText required title={translate('ci_extra_address_info')} value={data.address.extra_address_info} icon="Edit" onChange={(e) => changeAddress(e, "extra_address_info")} />
          </span>
          <span className="help">
            <Icon name="Info" />
            {translate('ci_example_extra_address_info')}&nbsp;
            <a href="https://ternat.100procentlokaal.be/zoeken/ternat/delhaize/pagina=1" target="_blank" className="sub bold">
              {translate('ci_example_extra_address_info_part2')}
            </a>
          </span>
        </form>
        <div className="section map">
          <div id="map">
            {bounds && bounds !== "" &&
            <MapMarker
              bounds={bounds}
              addresses={address}
              scrollTo={(e) => console.log(e)}
              getMap={(e) => console.log(e)}
              // zoomed={this.state.zoomed}
              setZoomed={(e) => console.log(e)}
              zoomLvl={16}
              page='change_marker'
              changeLngLat={(e) => changeLngLat(e)}
            />
          }
          </div>
          <span style={{"marginTop": "10px"}} className="help">
            <Icon name="Info" />
            {translate('ci_address_info_marker')}
          </span>
        </div>
      </div>
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(2);
            window.location.reload();
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            e.preventDefault();
            patchData(data, 4);
            store.dispatch({ type: "countUp" });
            props.setTab(4);
          }}
        />
      </div>
    </section>
  );
};

export default Address;
