// import { isMobile } from "react-device-detect";
import Translate from "@identitybuilding/idb-react-translations";
import { useHistory, useParams } from "react-router-dom";

const searchParams = new URLSearchParams(window.location.search);
let lang = searchParams.get("lang");
if (!lang) {
  lang = "nl"
  searchParams.set("lang", lang);
}

const initialState = {
  lang: lang,
  translate: (x) => Translate(x, lang),
  current_establishment: {},
  correctOTP: false,
  email: "",
  password: "",
  showNav: false,
  logged_in: true,
  open_contact: false,
  open_order: false,
  is_admin: true,
};

const GeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LANG":
      return { ...state, lang: action.lang };

    case "UPDATE_CURRENT_ESTABLISHMENT":
      return { ...state, current_establishment: action.establishment };

    case "UPDATE_CORRECT_OTP":
      return { ...state, correctOTP: action.value };

    case "UPDATE_EMAIL":
      return { ...state, email: action.value };

    case "UPDATE_PASSWORD":
      return { ...state, password: action.value };

    case "UPDATE_NAV":
      return { ...state, showNav: action.value };

    case "OPEN_CONTACT":
      return { ...state, open_contact: action.value };
    case "OPEN_ORDER":
      return { ...state, open_order: action.value };
    case "SET_IS_ADMIN":
      return { ...state, is_admin: action.value };


    default:
      return state;
  }
};

export default GeneralReducer;
