import { Fragment, useEffect, useRef, useState } from "react";
import "./App.scss";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import { updateEmail, updatePassword } from "./actions/GeneralAction";
import { OsnInputText, Button } from "@identitybuilding/idb-react-ui-elements";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Icon from "@identitybuilding/idb-react-iconlib";

const Signin = () => {
  const { est, ent } = useParams();
  const history = useHistory();
  const { lang, correctOTP } = useSelector((state) => state.general);
  const translate = useSelector((state) => state.general.translate);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [seeRepeatPassword, setSeeRepeatPassword] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

useEffect(() => {
  if (!correctOTP) { history.push(`/${ent}/${est}/login?lang=${lang}`) }


}, [])


  const createAccount = () => {
    if (correctOTP) {
      setError("");

      if (email === "") {NotificationManager.error("Gelieve email in te vullen");}
      else if (password === "") {NotificationManager.error("Gelieve wachtwoord in te vullen")}
      else if (passwordRepeat === "") {NotificationManager.error("Gelieve wachtwoord herhalen in te vullen")}
      else if (passwordRepeat.length < 8) { NotificationManager.error("Wachtwoord moet minstens 8 characters hebben!")}
      else if (password.length < 8) { NotificationManager.error("Wachtwoord moet minstens 8 characters hebben!")}
      else if ( passwordRepeat === password && email !== "" && password !== "" && passwordRepeat !== "") {
        axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/create/`, {
            headers: {'Content-Type': 'application/json'},
            email: email,
            password: password,
            passwordRepeat: passwordRepeat,
            est: est,
          })
          .then((res) => {
            if (res.data.status === "Succes") {
              localStorage.setItem("token", res.data.token);
              history.push(`/${ent}/${est}/?lang=${lang}`);
            } else {
              NotificationManager.error("Somehting went wrong");
            }
          })
          .catch((res) => {
            NotificationManager.error(res.response.data.status);
          });
      } else {
        NotificationManager.error("Wachtwoorden komen niet overeen!");
      }
    }
    else {
      NotificationManager.error("Er was geen geldige OTP ingegeven")
    }
};



  const onChange = (type, value) => {
    setError("");
    if (type === "email") {
      setEmail(value);
    } else if (type === "password") {
      setPassword(value);
    } else {
      setPasswordRepeat(value);
    }
  };
  return (
    <section className="signin">
      <div className="signin_wrapper">
        <h2>{translate('ci_create_an_account')}</h2>
        <form>
          <OsnInputText
            icon="Mail"
            title={translate('email')}
            onChange={(e) => onChange("email", e.target.value)}
          />
          <div style={{"position": "relative"}}>
          <OsnInputText
            icon="Locked"
            title={translate('password')}
            type={seePassword ? 'text' : 'password'}
            onChange={(e) => onChange("password", e.target.value)}
          />
          <Icon className="passwordIcon" name={seePassword ? "PasswordShow" : "PasswordHide"} onClick={() => setSeePassword(!seePassword)}/>
          </div>
          <div style={{"position": "relative"}}>
          <OsnInputText
            icon="Locked"
            title={translate('password_repeat')}
            type={seeRepeatPassword ? 'text' : 'password'}
            onChange={(e) => onChange("passwordrepeat", e.target.value)}
          />
          <Icon className="passwordIcon" name={seeRepeatPassword ? "PasswordShow" : "PasswordHide"} onClick={() => setSeeRepeatPassword(!seeRepeatPassword)}/>
          </div>

          <Button
            borderColor="sub"
            text={translate('ci_create_account')}
            txtColor="sub"
            type="sub"
            onClick={(e) => {
              createAccount();
            }}
          />
          {error}
        </form>
      </div>
      <NotificationContainer />
    </section>
  );
};

export default Signin;
