import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentEstablishment } from "../actions/GeneralAction";

import { Button, OsnCheckbox, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
import { useHistory, useParams } from "react-router-dom";

import store from "../store/index";
import Icon from "@identitybuilding/idb-react-iconlib";

const Welcome = (props) => {
  const [data, setData] = useState(props.data);
  const [loaded, setLoaded] = useState(props.data);
  let { est, ent } = useParams();
  const lang = useSelector((state) => state.general.lang);
  const is_admin = useSelector((state) => state.general.is_admin);
  const translate = useSelector((state) => state.general.translate);

  const dispatch = useDispatch();
  
  const patchData = (data, tab) => {
    let copy = data
    if(tab > copy.available_tabs){copy.available_tabs = tab}
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "welcome"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const changeData = (value, type) => {
    let copy = data;

    copy[type] = value;
    if (type === "accept_GDPR" && value === false) {copy.enter_competition = false;}
    if (type === "accept_terms" && value === false) {copy.enter_competition = false;}

    setData(copy);
    setLoaded(false);
  };

  const changeWelcome = () => {
    let copy = data;
    
    patchData(data, 1);
    if ((data.accept_GDPR && data.accept_terms && data.establishment_active) || is_admin) {
      store.dispatch({ type: "countUp", data: "im gone test" });
  }

    props.setTab(1);
    setData(copy);
    setLoaded(false);
  };

  const changeSelect = (e) => {
    const copy = data;

    copy.support_city_meaning = e.id;

    setData(copy);
    setLoaded(false);
  };

  const changeContest = () => {
    let copy = data;

    if (!is_admin) {
      if(!copy.establishment_active) {props.createNotification("warning", "deze actie is niet mogelijk aangezien uw onderneming op inactief staat.")}
      else if(!copy.accept_GDPR) {props.createNotification("warning", "deze actie is niet mogelijk aangezien u onze GDPR/AVG voorwaarden niet geaccepteerd heeft.")}
      else if(!copy.accept_terms) {props.createNotification("warning", "deze actie is niet mogelijk aangezien u onze Algemene voorwaarden niet geaccepteerd heeft.")}
      else if (copy.establishment_active) {copy.enter_competition = !copy.enter_competition}
    }
    else {
      copy.enter_competition = !copy.enter_competition
    }
      setData(copy);

    setLoaded(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setData(props.data);
    setLoaded(true);
  });

  return (
    <section className="c-welcome">
      <div className="intro">
        <h2>
          <span className="typo">01</span>
        </h2>
        <div>
          <h2>{translate('welcome_to_local')}</h2>
          <p>
            {translate('thank_you_for_participating')} {translate('we_help_as_much')} <br/>
            {translate('this_additional_info')} 
            {/* Dank je!...om mee te werken aan het beheren en optimaliseren van de gegevens van jouw onderneming. Wij helpen je zoveel mogelijk! <br /> 
            Deze bijkomende informatie zal nuttig zijn voor elke bezoeker die jouw onderneming heeft gevonden. */}
          </p>
        </div>
      </div>

      {data && (
        <div className="container">
          <div className=" option-container container-item">
            <h1 className="yellow">{translate('my_company_active')}</h1>
            <div className="card-data">
              <div
                onClick={() => {
                  let copy = data;
                  copy.establishment_active = !copy.establishment_active;
                  if (!copy.establishment_active) {
                    copy.enter_competition = false;
                  }
                  setData(copy);
                  setLoaded(false);
                  patchData(data, 0);
                }}
                className={["option", data.establishment_active ? "active" : ""].join(" ")}
              >
                <p className="typo">{translate('ci_yes')}</p>
                <p className="typo">{translate('ci_no')}</p>
              </div>
              {!data.establishment_active && (
                <div className={[data.establishment_active ? "active" : "", "false-info"].join(" ")}>
                  <Icon name="Info" />
                  <p className="typo">{translate('your_company_deactivated')}</p>
                </div>
              )}
              {data.establishment_active && (
                <div id={data.establishment_active ? "true" : ""} className={[data.establishment_active ? "active" : "", "false-info"].join(" ")}>
                  <Icon name="Info" />
                  <p className="typo">{translate('now_we_can_continue')}</p>
                </div>
              )}
            </div>
            {data.establishment_active && (
              <form className="conditions">
                <label>
                  <OsnCheckbox
                    onChange={(e) => {
                      changeData(e, "accept_GDPR");
                      patchData(data, 0);
                    }}
                    name="accept_GDPR"
                    checked={data.accept_GDPR}
                    type="checkbox"
                    value={translate('i_accept_GDPR')}
                  />
                </label>
                <p className="detail">{translate('last_accepted_on')}: {new Date(data.last_accepted_GDPR).toLocaleDateString('en-GB') === 'Invalid Date' ? '' : new Date(data.last_accepted_GDPR).toLocaleDateString('en-GB')}</p>
                <OsnCheckbox
                  checked={data.accept_terms}
                  name="accept_terms"
                  onChange={(e) => {
                    changeData(e, "accept_terms");
                    patchData(data, 0);
                  }}
                  // value={` Ik aanvaard de algemene voorwaarden.
                  // `}
                  value={[
                    <span key={"text before title"}>{translate('i_accept_the')}&nbsp;</span>,
                    <a className="sub" key={"link"} href={lang === 'nl' ? "https://www.ondernemersnetwerk.be/privacy/policy#terms" : "https://reseauentrepreneurs.be/privacy/policy"} target="_blank" rel="noreferrer">
                      {translate('general_terms_and_conditions')}
                    </a>,
                  ]}
                />
                <p className="detail">{translate('last_accepted_on')}: {new Date(data.last_accepted_terms).toLocaleDateString('en-GB') === 'Invalid Date' ? '' : new Date(data.last_accepted_terms).toLocaleDateString('en-GB')}</p>
                <OsnCheckbox
                  checked={data.accept_share_with_municipality}
                  type="checkbox"
                  name="accept_share_with_municipality"
                  value={translate('i_accept_my_municipality')}
                  onChange={(e) => {
                    changeData(e, "accept_share_with_municipality");
                  }}
                />
                <label className="select">
                  {data.municipality_has_collaboration ? 
                    translate('city_support_good_idea')
                  : 
                    translate('would_you_find_city_support_good_idea')
                  }
                  
                  <OsnSelect
                    onChange={(e) => changeSelect(e)}
                    active={data.support_city_meaning === 0 ? translate('please_choose_an_option') : data.support_city_meaning === 1 ? data.municipality_has_collaboration ? translate('yes_i_appreciate_support_city') : translate('yes_i_would_appreciate_support_city') : translate('no_opinion_about_project')}
                    options={[
                      {
                        id: 0,
                        name: translate('please_choose_an_option'),
                      },
                      {
                        id: 1,
                        name: data.municipality_has_collaboration ? translate('yes_i_appreciate_support_city') : translate('yes_i_would_appreciate_support_city')
                      },
                      {
                        id: 2,
                        name: translate('no_opinion_about_project'),
                      },
                    ]}
                  />
                </label>
              </form>
            )}
          </div>
          <div className="option-container container-item">
            <h1>
              {translate('contest')}&nbsp;<span className="typo">{translate('you_are_participating_too_right')}</span>
            </h1>
            <div onClick={() => { changeContest();}} className={["option", data.enter_competition ? "active" : ""].join(" ")}>
              <p className="typo">{translate('ci_yes')}</p>
              <p className="typo">{translate('ci_no')}</p>
            </div>
            {!data.enter_competition && (
              <div className={[data.enter_competition ? "active" : "", "false-info"].join(" ")}>
                <Icon name="Info" />
                <p className="typo">{translate('you_do_not_know_what_missing')}</p>
              </div>
            )}
            {data.enter_competition && (
              <div id={data.enter_competition ? "true" : ""} className={[data.enter_competition ? "active" : "", "false-info"].join(" ")}>
                <Icon name="Info" />
                <p className="typo">{translate('fantastic')}</p>
              </div>
            )}

            <p>
              {translate('for_the')} <b className="yellow">{translate('businesspage_100procentlocal')}</b>, {translate('businesspage_100procentlocal_part_2')}
            </p>
            <p>{translate('businesspage_100procentlocal_part_3')}</p>
            <p>
              <strong>{translate('attention')}</strong> {translate('businesspage_100procentlocal_part_4')}
            </p>
            <a target="_blank" rel="noreferrer" href={lang === 'nl' ? "https://zappa-tlaqv351d.s3.eu-central-1.amazonaws.com/media/documents/contest-rules-nl.pdf" : "https://zappa-tlaqv351d.s3.eu-central-1.amazonaws.com/media/documents/reglement-du-concours.pdf"} className="help">
              {translate('contest_rules')}
            </a>
          </div>
        </div>
      )}
      <div className={["button-container", (!data.accept_GDPR || !data.accept_terms || !data.establishment_active) && !is_admin ? "disabled" : ""].join(" ")}>
        <Button text={translate('next')} type="sub" size="S" brderColor="main" onClick={(e) => changeWelcome()} />
      </div>
    </section>
  );
};

export default Welcome;
