import { useRef, useState, useEffect } from "react";
import axios from "axios";
import store from "../store";
import plenny from "../library/images/plenny-point.png";
import { Button, OsnCheckbox } from "@identitybuilding/idb-react-ui-elements";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const Kadobon = (props) => {
  const [data, setData] = useState(props.data);
  const [loaded, setLoaded] = useState(true);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();

  const patchData = (data, tab) => {
    let copy = data
        if(tab > copy.available_tabs){copy.available_tabs = tab}
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "kadobon"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const changeKadobon = () => {
    let copy = data;
    copy.allow_local_voucher = !copy.allow_local_voucher;
    setData(copy);
    setLoaded(!loaded);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (data.local_voucher.municipality){
    var temp = document.getElementById('templateText');
    temp.innerHTML = data.local_voucher.text
  }
  }, [])
  
  return (
    data.local_voucher.municipality ?
    <section className="c-kadobon">
      <div className="intro">
        <h2>
          <span className="typo">08</span>
        </h2>
        <div>
          <h2>
            {data.local_voucher && data.local_voucher.municipality} {translate('offers_the')} "{data.local_voucher && data.local_voucher.name}" {lang === "nl" && 'aan'}:
          </h2>
          <p>{data.local_voucher && data.local_voucher.title}</p>
        </div>
      </div>
      {data.local_voucher && (
        <div className="section-container">
          <div className="info">
            <div id="templateText"></div>
            <br />
            <div className="c-kadobon__info">
              {data.local_voucher.url && 
              <Button
              text={data.local_voucher.name}
              type="sub"
              size="S"
              brderColor="main"
              onClick={(e) => {
                window.open(data.local_voucher.url, "_blank");
              }}
              />
            }
              <br />
            </div>

            <div>
              <OsnCheckbox
                checked={data.allow_local_voucher}
                name="accept"
                onChange={(e) => {
                  changeKadobon();
                }}
                value={translate('i_allow_my_customers')}
              />
            </div>
          </div>
          <div className="plenny-container">
            <img alt="plenny" src={plenny} />
          </div>
        </div>
      )}

      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(6);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            patchData(data, 8);
            store.dispatch({ type: "countUp" });
            props.setTab(8);
          }}
        />
      </div>
    </section>
    :
    <section className="c-kadobon">
      <div className="intro">
        <h2>
          <span className="typo">08</span>
        </h2>
        <div>
          <h2>
            Momenteel is er geen aanbod voor een cadeaubon
          </h2>
          <p></p>
        </div>
      </div>
      {data.local_voucher && (
        <div className="section-container">
          <div className="info">
            <div id=""></div>
            <br />
            <div className="c-kadobon__info">

              <br />
            </div>


          </div>
          <div className="plenny-container">
            <img alt="plenny" src={plenny} />
          </div>
        </div>
      )}

      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(6);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            patchData(data, 8);
            store.dispatch({ type: "countUp" });
            props.setTab(8);
          }}
        />
      </div>
    </section>
  );
};

export default Kadobon;
