import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Icon from "@identitybuilding/idb-react-iconlib";
import React, { useState } from "react";
import "../library/Navigation.css"

const Navigation = (props) => {
    const [networks, setNetworks] = useState(false);

    const lang = useSelector((state) => state.general.lang);
    const nis = useSelector((state) => state.general.nis);
    const translate = useSelector((state) => state.general.translate);


    const LogoSA = require("../library/logos/logos/logo_shoppaworld.svg").default;
    const LogoSA_Invert = require("../library/logos/logos/logo_shoppaworld-invert.svg").default;
    const LogoPN = require(`../library/logos/logos/pn/logo_pn_${lang}.svg`);
    const LogoPN_Invert = require(`../library/logos/logos/pn/logo_pn_invert_${lang}.svg`);
    const LogoCN = require(`../library/logos/logos/cn/logo_cn_${lang}.svg`);
    const LogoCN_Invert = require(`../library/logos/logos/cn/logo_cn_invert_${lang}.svg`);
    const LogoESN = require(`../library/logos/logos/esn/logo_esn_${lang}.svg`);
    const LogoESN_Invert = require(`../library/logos/logos/esn/logo_esn_invert_${lang}.svg`);
    const LogoPL = require(`../library/logos/logos/pl/logo_pl_${lang != 'en' ? lang : 'nl'}.svg`);
    const LogoPL_Invert = require(`../library/logos/logos/pl/logo_pl_invert_${lang != 'en' ? lang : 'nl'}.svg`);
    let networkFunction = "";

    // let value_1 = props.match && props.match.params.value_1 ? props.match.params.value_1.replace(/\+/g, " ") : "";
    let path = "";

    const timeoutFunction = () => {
        networkFunction = setTimeout(() => {
            setNetworks(false);
        }, 750);
    };

    return (
        <nav
            className={networks ? "active" : ""}
            id={props.Zindex ? "active" : ""}
            onMouseLeave={() => networks && timeoutFunction()}
            onMouseOverCapture={() => networks && clearTimeout(networkFunction)}
        >
            {/* Networks navigation */}
            <div className="networks">
                <a
                    className="osn networkLogo"
                    href={
                        lang === "nl"
                            ? "https://100procentlokaal.be"
                            : "https://www.100pourcentlocale.be"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        className="lazyload logo"
                        src={LogoPL_Invert}
                        alt={translate("pl_alt")}
                    />
                    <img
                        className="lazyload logo logoOriginal"
                        src={LogoPL}
                        alt={translate("pl_alt")}
                    />
                </a>
                <a
                    className="osn networkLogo"
                    href={
                        lang === "nl"
                            ? "https://ondernemersnetwerk.be"
                            : "https://www.reseauentrepreneurs.be"
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        className="lazyload logo"
                        src={LogoESN_Invert}
                        alt={translate("esn_alt")}
                    />
                    <img
                        className="lazyload logo logoOriginal"
                        src={LogoESN}
                        alt={translate("esn_alt")}
                    />
                </a>
                <a
                    className="cn networkLogo"
                    href="https://catalogusnetwerk.be"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        className="lazyload logo"
                        src={LogoCN_Invert}
                        alt={translate("cn_alt")}
                    />
                    <img
                        className="lazyload logo logoOriginal"
                        src={LogoCN}
                        alt={translate("cn_alt")}
                    />
                </a>
                <a
                    className="pn networkLogo"
                    href="https://promonetwerk.be"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        className="lazyload logo"
                        src={LogoPN_Invert}
                        alt={translate("pn_alt")}
                    />
                    <img
                        className="lazyload logo logoOriginal"
                        src={LogoPN}
                        alt={translate("pn_alt")}
                    />
                </a>
                {/* <a
                    className="sa networkLogo"
                    href="https://shoppa.world"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        src={LogoSA_Invert}
                        className="lazyload logo"
                        alt="Logo ShoppA.world"
                    />
                    <img
                        className="lazyload logo logoOriginal"
                        src={LogoSA}
                        alt="Logo ShoppA.world"
                    />
                </a> */}
            </div>

            {/* Navigation menu */}
            <div className="nav_menu">
                {/* Navigation logo */}
                {/* <Link className="navLogo" to="/">
          <img
            height="25px"
            width="25px"
            alt={translate("esn_alt")}
            className="logo logoOriginal"
            src={require(`../library/logos/logos/esn/logo_esn_small_${lang}.svg`)}
          />
          <img
            height="25px"
            width="25px"
            alt={translate("esn_alt")}
            className="logo logoOriginal"
            src={require(`../library/logos/logos/esn/logo_esn_${lang}.svg`)}
          />
        </Link> */}

                {/* Navigation menu items */}
                <div className="nav_buttons">
                    {/* Button to open the networks menu */}
                    {/* <a
            className="nav_button my_company"
            href={`https://${lang === "fr" ? "modifie.be" : "wijzig.be"}`}
            rel="noreferrer noopener"
            target="_blank"
          >
            {translate("my_company")}
          </a> */}
                    <div
                        className={`nav_button network ${networks ? "active" : ""}`}
                        onClick={() => setNetworks(!networks)}
                    >
                        {translate("networks")}
                    </div>

                    {/* Language dropdownmenu */}
                    <div className="nav_button">
                        <Icon name="Website" />
                        {/* {lang === "nl"
                            ? "Nederlands"
                            : lang === "fr"
                                ? "Français"
                                : "English"} */}
                        {
                            lang === "nl"
                                ? "Nederlands"
                                : lang === "fr"
                                    ? "Français"
                                    : lang === "de"
                                        ? "Deutsch"
                                        : "English"
                        }

                            <div className="lang_options">
                                {/* {lang === "nl" ? (
                                    <a rel="nofollow noreferrer" href={`https://wijzig.100procentlokaal.be?lang=fr`}>Français</a>
                                ) : (
                                    <a rel="nofollow noreferrer" href={`https://wijzig.100procentlokaal.be?lang=nl`}>Nederlands</a>
                                )} */}
                                {/* {lang === "nl" ? (
                                    <a rel="nofollow noreferrer" href={`${location.origin}?lang=fr`}>Français</a>
                                ) : lang === "fr" ? (
                                    <a rel="nofollow noreferrer" href={`${location.origin}?lang=nl`}>Nederlands</a>
                                ) : lang === "de" ? (
                                    <a rel="nofollow noreferrer" href={`${location.origin}?lang=en`}>English</a>
                                ) : (
                                    <a rel="nofollow noreferrer" href={`${location.origin}?lang=de`}>Deutsch</a>
                                )} */}
                                <a rel="nofollow noreferrer" href={`${location.origin}?lang=fr`}>Français</a>
                                <a rel="nofollow noreferrer" href={`${location.origin}?lang=nl`}>Nederlands</a>
                                <a rel="nofollow noreferrer" href={`${location.origin}?lang=en`}>English</a>
                                <a rel="nofollow noreferrer" href={`${location.origin}?lang=de`}>Deutsch</a>

                                {/* {lang === "en" ? <a href="https://reseauentrepreneurs.be">Français</a>
                                    : <a href="https://entrepreneursnetwork.be">English</a>} */}
                            </div>

                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
