import store from "../store";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import Icon from "@identitybuilding/idb-react-iconlib";
import { Button, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import plenny from "../library/images/plenny-point.png";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const Hyperlink = (props) => {
  const [loaded, setLoaded] = useState(true);
  const [data, setData] = useState(props.data);
  const lang = useSelector((state) => state.general.lang);
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();


  const patchData = (data, tab) => {
    let copy = data
        if(tab > copy.available_tabs){copy.available_tabs = tab}
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "hyperlink"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const changeHyperlink = (value, target) => {
    if (target){
      let copy = data;
      copy[`business_${target}_nl`] = value;
      let values = copy.contact_info.filter((value) => value.contact_method.medium.toLowerCase() === target.toLowerCase())
      if(values.length > 0){
        values[0].contact_method.value = value  
      }
      else {
        // create one
        copy.contact_info.push({
            "score": 10,
            "datasource": "CUSTOMER",
            "contact_method": {
                "medium": target.charAt(0).toUpperCase() + target.slice(1),
                "value": value,
                "name": "",
                "new_value": ""
            }
        })
      }
      setData(copy);
      dispatch(updateCurrentEstablishment(copy));
      setLoaded(false);
  }

  };

  const getContactInfo = (type) => {
    let values = []
    for (let i = 0; i < data.contact_info.length; i++) {
      let item = data.contact_info[i]
      if(item.contact_method.medium === type) {
        values.push(item)
      }

    }
    const priority = [
      "CUSTOMER",
      "OSN",
      "KBO",
      ]
  
    let new_values = values.sort((a, b) => {
      if (priority.indexOf(a.datasource) > priority.indexOf(b.datasource)) return 1;
      if (priority.indexOf(a.datasource) < priority.indexOf(b.datasource)) return -1;
    })
    if(new_values.length > 0) { return new_values[0].contact_method.value}
    else {return ""}
    
  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    let copy = props.data
    copy.business_mobile_nl = getContactInfo('Mobile') 
    copy.business_fixed_nl = getContactInfo('Fixed') 
    copy.business_email_nl = getContactInfo('Email') 
    copy.business_fax_nl = getContactInfo('Fax') 
    copy.business_website_nl = getContactInfo('Website') 
    copy.business_webshop_nl = getContactInfo('Webshop') 
    copy.business_facebook_nl = getContactInfo('Facebook')
    copy.business_instagram_nl = getContactInfo('Instagram')
    copy.business_youtube_nl = getContactInfo('YouTube')
    copy.business_linkedin_nl = getContactInfo('LinkedIn')
    copy.business_twitter_nl = getContactInfo('Twitter')
    copy.business_tiktok_nl = getContactInfo('TikTok')
    copy.business_snapchat_nl = getContactInfo('Snapchat')
    copy.business_spotify_nl = getContactInfo('Spotify')
    setData(copy);
    setLoaded(true);
  }, []);

  return (
    <section className="c-hyperlink">
      <div className="intro">
        <h2>
          <span className="typo">06</span>
        </h2>
        <div>
          <h2>{translate('add_your_internet_links')}</h2>
          <span className="intro-text">
            {translate('show_your_visitors')}
            <br />
            <Icon name="Info" /> {translate('as_a_check')}
            <b className="yellow"> {translate('http_https')}</b>
          </span>
        </div>
      </div>

      {data && (
        <div className="section-container">
          <form>
            <label>
              <OsnInputText className="website" icon="Website" defaultValue={getContactInfo("Website")} title={translate('website')} onBlur={(e) => changeHyperlink(e.target.value, "website")} />
            </label>
            <label>
              <OsnInputText className="webshop" icon="Eshop" defaultValue={getContactInfo("Webshop")} title={translate('webshop')} onBlur={(e) => changeHyperlink(e.target.value, "webshop")} />
            </label>
            <label>
              <OsnInputText className="facebook" icon="Facebook" defaultValue={getContactInfo("Facebook")} title="Facebook" onBlur={(e) => changeHyperlink(e.target.value, "facebook")} />
            </label>
            <label>
              <OsnInputText className="instagram" icon="Instagram" defaultValue={getContactInfo("Instagram")} title="Instagram" onBlur={(e) => changeHyperlink(e.target.value, "instagram")} />
            </label>
            <label>
              <OsnInputText className="youtube" icon="Youtube" defaultValue={getContactInfo("YouTube")} title="YouTube" onBlur={(e) => changeHyperlink(e.target.value, "youtube")} />
            </label>
            <label>
              <OsnInputText className="linkedin" icon="Linkedin" defaultValue={getContactInfo("LinkedIn")} title="Linkedin" onBlur={(e) => changeHyperlink(e.target.value, "linkedin")} />
            </label>
            <label>
              <OsnInputText className="twitter" icon="Twitter" defaultValue={getContactInfo("Twitter")} title="Twitter" onBlur={(e) => changeHyperlink(e.target.value, "twitter")} />
            </label>
            <label>
              <OsnInputText className="tiktok" icon="TikTok" defaultValue={getContactInfo("TikTok")} title="TikTok" onBlur={(e) => changeHyperlink(e.target.value, "tiktok")} />
            </label>
            <label>
              <OsnInputText className="snapchat" icon="Snapchat" defaultValue={getContactInfo("Snapchat")} title="Snapchat" onBlur={(e) => changeHyperlink(e.target.value, "snapchat")} />
            </label>
            <label>
              <OsnInputText className="spotify" icon="Spotify" defaultValue={getContactInfo("Spotify")} title="Spotify" onBlur={(e) => changeHyperlink(e.target.value, "spotify")} />
            </label>
          </form>
          <div className="plenny-container">
            <img src={plenny} />
          </div>
        </div>
      )}
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(4);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            e.preventDefault();
            patchData(data, 6);
            store.dispatch({ type: "countUp" });
            changeHyperlink(e);
            props.setTab(6);
          }}
        />
      </div>
    </section>
  );
};

export default Hyperlink;
