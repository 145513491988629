import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, OsnInputDate, OsnInputText, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
import Icon from "@identitybuilding/idb-react-iconlib";
import { Redirect } from "react-router";
import { openContact, openOrder } from "./actions/GeneralAction";
import OrderModule from './components/OrderModule'
import PolygonTest from './components/PolygonTest'

import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import App from "./App";
import Login from "./login";
import PasswordReset from "./PasswordReset";
import Signin from "./Signin";
import Main from "./Main";
import axios from "axios";

import "./index.css";
import "@identitybuilding/idb-react-ui-elements/dist/styles/Colors.css";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

const Environment = ({ }) => {
  const [loaded, setLoaded] = useState(false);
  const history = useHistory()
  const dispatch = useDispatch()
  const forceUpdate = useForceUpdate();
  const [helloLanguage, setHelloLanguage] = useState("Kies jouw taal");
  const ent = window.location.pathname.split('/')[1]
  const est = window.location.pathname.split('/')[2]
  const { open_contact, open_order, translate, lang, is_admin, current_establishment } = useSelector((state) => state.general);


  useEffect(() => {
    if (localStorage.getItem('lang')) {
      if (!window.location.href.includes('lang=')) { window.location.href = `${window.location.href}?lang=${localStorage.getItem('lang')}` }
    }
    else {
      if (window.location.href.includes('lang=')) {
        localStorage.setItem("lang", window.location.search.replace('?lang=', '').replace('&orderbon=true', ''));
      }
      setLoaded(true)
    }


    if (window.location.href.includes('orderbon=true')) {
      dispatch(openOrder(true))
    }

  }, [])

  useEffect(() => {
    let body = document.getElementsByTagName('body')[0]
    if (open_order === true) {
      body.style.overflow = 'hidden'
    }
    else {
      body.style.overflow = 'auto'

    }
  }, [open_order])

  const openOrdersModule = () => {
    window.location.href = `/${ent}/${est}/orders`
  }


  return (
    <div id="environment">
      {loaded && !window.location.href.includes('lang=') &&
        <React.Fragment>
          <div className="languagePopup">
            <figure>
              {/* <h2>{helloLanguage}</h2> */}
              <img src={require("./library/images/plenny-point.png")} alt="plenny login" />
            </figure>
            <div className="body">
              <div className="languageblock">
                <div onMouseOver={() => setHelloLanguage('Kies jouw taal')} className="languageblock_inside">
                  <p>Hallo, ik ben Plenny!</p>
                  <p>Ik begeleid je graag doorheen dit traject.</p>
                  <Button
                    borderColor="main"
                    text="Ga verder in het Nederlands"
                    txtColor="sub"
                    type="sub"
                    size="S"

                    onClick={(e) => {
                      localStorage.setItem("lang", 'nl');
                      window.location.href = `${window.location.href.replace('?lang=nl', '').replace('?lang=fr', '').replace('?lang=de', '').replace('?lang=en', '')}?lang=nl`
                    }
                    }
                  />
                </div>
              </div>
              <div className="languageblock">
                <div onMouseOver={() => setHelloLanguage('Choose your language')} className="languageblock_inside">
                  <p>Hi, I'm Plenny!</p>
                  <p>I will happily guide you through this process.</p>
                  <Button
                    borderColor="main"
                    text="Continue in English"
                    txtColor="sub"
                    type="sub"
                    size="S"
                    onClick={(e) => {
                      localStorage.setItem("lang", 'en');
                      window.location.href = `${window.location.href.replace('?lang=nl', '').replace('?lang=fr', '').replace('?lang=de', '').replace('?lang=en', '')}?lang=en`
                    }
                    }
                  />
                </div>
              </div>
              <div className="languageblock">
                <div onMouseOver={() => setHelloLanguage('Choisissez votre langue')} className="languageblock_inside">
                  <p>Salut, je suis Plenny !</p>
                  <p>Je serais heureux de vous guider dans ce processus.</p>
                  <Button
                    borderColor="main"
                    text="Continuer en français"
                    txtColor="sub"
                    type="sub"
                    size="S"
                    onClick={(e) => {
                      localStorage.setItem("lang", 'fr');
                      window.location.href = `${window.location.href.replace('?lang=nl', '').replace('?lang=fr', '').replace('?lang=de', '').replace('?lang=en', '')}?lang=fr`
                    }
                    }
                  />
                </div>
              </div>
              <div className="languageblock">
                <div onMouseOver={() => setHelloLanguage('Wähle deine Sprache')} className="languageblock_inside">
                  <p>Hallo, ich bin Plenny!</p>
                  <p>Gerne begleite ich Sie durch diesen Prozess</p>
                  <Button
                    borderColor="main"
                    text="Weiter auf Deutsch"
                    txtColor="sub"
                    type="sub"
                    size="S"
                    onClick={(e) => {
                      localStorage.setItem("lang", 'de');
                      window.location.href = `${window.location.href.replace('?lang=nl', '').replace('?lang=fr', '').replace('?lang=de', '').replace('?lang=en', '')}?lang=de`
                    }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg_languagePopup"></div>
        </React.Fragment>
      }
      <BrowserRouter>
        <Switch>
          <Route exact path="/:ent/:est/orders" render={() => <OrderModule />} />
          <Route exact path="/polygon-test" render={() => <PolygonTest />} />
          <Route exact path="/" render={() => <Main />} />
          <Route exact path="/:ent" render={() => <Main />} />
          <Route exact path="/:ent/:est/login" render={() => <Login />} />
          <Route exact path="/:ent/:est/signin" render={() => <Signin />} />
          <Route path="/reset/:token" render={() => <PasswordReset />} />
          <Route exact path="/:ent/:est/" render={() => <App />} />
        </Switch>
      </BrowserRouter>
      {/* <div className="contact_us_icon">
        <Button bgColor={['sub', 'main', 'sub']} icon='Phone' iconColor='white' size='L' type='icon' />
      </div> */}
      {/* <div className="contact_us">

      </div> */}
      <div className="floatingButton">
        <div className="button-icon"><Icon name="Info" /></div>
        <div className="floating-content">
          {/* <a className="icon-wrapper" href={`/order/${est}?lang=${lang}`}>
              <Icon name="Briefcase"/>
          </a> */}
          <div className="icon-wrapper" onClick={() => dispatch(openContact(true))}>
            <Icon name="Mail" />
          </div>
          {current_establishment.is_admin &&
            <div className="icon-wrapper" onClick={() => openOrdersModule()}>
              <Icon name="Briefcase" />
            </div>}
          <a href={`mailto:support@ondernemersnetwerk.be?subject=Aanvraag OTP ${est}&body=Beste %0D%0D Graag had ik mijn OTP gekregen om mijn gegevens te optimaliseren! %0D U kan mijn contacteren via onderstaande gegevens: %0D%0D Ondernemingsnummer: ${ent} %0D Vestigingnummer: ${est} %0D Voornaam: %0D Naam: %0D Telefoonnummer:%0D%0D Met vriendelijke groeten`} className="icon-wrapper" style={{ display: window.location.pathname !== '/' ? 'flex' : 'none' }}>
            <Icon name="Protection" />
          </a>
        </div>
      </div>

      {open_contact &&
        <div className="contactBlock">
          <div className="content">
            <Icon className="close-icon" name="Close" onClick={() => dispatch(openContact(false))} />
            <div className="side left">
              {/* Erik */}
              <div className="contact-card">
                <figure><img src={require("./library/images/erik.png")} /></figure>
                <div className="contact-card-content">
                  <span>
                    <h2>Erik Vercruysse</h2>
                    <h3>Founder & sales manager</h3>
                  </span>
                  <div className="contacts">
                    <a href="mailto:erik@idb.be" className="contact-block"><Icon name="Mail" />{translate('contact_me')}</a>
                    <a href="callto:+32497579777" className="contact-block"><Icon name="Phone" />+32 497 57 97 77</a>
                  </div>
                  <div className="socials">
                    <a target="_blank" href="https://www.facebook.com/erik.vercruysse"><Icon name="Facebook" /></a>
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=32497579777&text=Hey,%20stuur%20gerust%20een%20berichtje%20en%20ik%20antwoord%20je%20zo%20snel%20mogelijk!"><Icon name="Whatsapp" /></a>
                    <a target="_blank" href="https://www.linkedin.com/in/erik-vercruysse-22438576/?trk=people-guest_profile-result-card_result-card_full-click&originalSubdomain=be"><Icon name="Linkedin" /></a>
                  </div>
                </div>
              </div>

            </div>
            <div className="side right">
              <figure> <img src={require('./library/images/logos/idb/logo_idb_secundair.svg').default} /> </figure>
              <div className="container">
                <div className="contact-us">
                  <h2>{lang === 'nl' ? 'CONTACTEER ONS' : 'CONTACTEZ-NOUS'}</h2>
                  <a href="mailto:office@identitybuilding.be" className="contact-block"><Icon name="Mail" />{lang === 'nl' ? 'Mail ons' : 'Envoyez-nous un e-mail'}</a>
                  <a href="callto:+3253810777" className="contact-block"><Icon name="Phone" />+32 53 81 07 77</a>
                </div>
                <div className="address">
                  <h2>{lang === 'nl' ? 'KOM LANGS' : 'PASSER'}</h2>
                  <img
                    style={{ padding: "0" }}
                    className="icon"
                    src={require("./library/logos/nismarker.svg").default}
                    alt="marker icon"
                  />
                  <span>
                    Kuilstraat 1 <br />
                    9420 Erondegem
                  </span>
                </div>
                <div className="social-media">
                  <h2>{lang === 'nl' ? 'SOCIALE MEDIA' : 'RÉSEAUX SOCIAUX'}</h2>
                  <a target="_blank" href="https://www.facebook.com/identityBuildingBelgium/"><Icon name="Facebook" /></a>
                  <a target="_blank" href="https://www.instagram.com/identitybuilding/"><Icon name="Instagram" /></a>
                  <a target="_blank" href="https://twitter.com/idBuilding_"><Icon name="Twitter" /></a>
                  <a target="_blank" href="https://www.linkedin.com/company/identitybuilding/"><Icon name="Linkedin" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="background" onClick={() => dispatch(openContact(false))}></div>
        </div>
      }
      {open_order &&
        <OrderModule />
      }

    </div>
  );
};

export default Environment;
